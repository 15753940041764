<template>
  <div class="HealthCheckList">
    <div class="box" v-for="item in list" @click="$router.push({path: '/HealthCheck',query: {id: item.HealthCheckId}})">
      <div class="box-l">
        <h3>编号:{{item.HealthCheckId.slice(0,8).toLocaleUpperCase()}}</h3>
        <p>{{item.HealthCheckEditTimeString}}</p>
      </div>
      <el-button @click.stop="xzfc(item.HealthCheckId)">复查</el-button>
      <img class="upimg" src="../../assets/img/xg.png" alt="">
    </div>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "HealthCheckList",
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getxx()
  },
  methods: {
    getxx() {
      this._api.publicApi.getHealthCheckList()
          .then(res => {
            if (res.GetListResult && res.GetListResult.length) {
              this.list = res.GetListResult
            }
          })
    },
    xzfc(id) {
      this._api.physicianVisits.addNewChoose(id)
      .then(res => {
        if (res.InsertResult) {
          this.sx = true
          this.$store.commit('physicianVisits/upIsqm', true)
          this.$store.commit('physicianVisits/upissgqm', true)
          this.$store.commit('physicianVisits/upXzConsulting',  res.InsertResult.Choose.ChooseId)
          this.$store.commit('physicianVisits/upJump', 1)
          this.$store.commit('users/upCsObj', JSON.parse(JSON.stringify(res.InsertResult.Choose)))
          pf.initTime()
          this.$router.push({path: '/'})
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .HealthCheckList {
    padding: 0 5px;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecebeb;
    padding: 4px 0;
    margin-bottom: 4px;
    cursor: pointer;
    .box-l {
      width: 5rem;
      padding-left: 0.5rem;
      text-align: left;
      h3 {
        font-size: 17px;
        padding-top: 10px;
      }
      p {
        padding: 15px 0;
      }
    }
    .upimg {
      display: block;
      width: 25px;
      height: auto;
      margin-right: 0.5rem;
    }
  }
</style>
